import React from "react";

import { IconProps } from "./IconProps";

export const Star = ({ color, className }: IconProps) => {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill={color || "currentColor"}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9.47693 4.62536L13.2652 5.19908C13.5831 5.25372 13.848 5.47228 13.9539 5.80013C14.0599 6.10065 13.9804 6.45581 13.742 6.67438L10.9869 9.48836L11.6492 13.4771C11.7022 13.805 11.5697 14.1328 11.3048 14.324C11.0399 14.5426 10.6955 14.5426 10.4041 14.406L7.01325 12.5209L3.59588 14.406C3.33096 14.5426 2.96009 14.5426 2.72167 14.324C2.45675 14.1328 2.3243 13.805 2.37728 13.4771L3.01307 9.48836L0.257981 6.67438C0.0195599 6.45581 -0.0599138 6.10065 0.0460511 5.80013C0.152016 5.47228 0.416928 5.25372 0.734823 5.19908L4.54956 4.62536L6.245 0.991764C6.37746 0.691242 6.66886 0.5 7.01325 0.5C7.33114 0.5 7.62254 0.691242 7.755 0.991764L9.47693 4.62536Z' />
    </svg>
  );
};

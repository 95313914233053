import React from "react";

export const Filter = ({ fill = "#A4A2BD", width = 11, height = 12 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 11 12'
    >
      <path
        d='M0.25 2.75C0.25 2.53125 0.320312 2.35156 0.460938 2.21094C0.601562 2.07031 0.78125 2 1 2H10C10.2188 2 10.3984 2.07031 10.5391 2.21094C10.6797 2.35156 10.75 2.53125 10.75 2.75C10.75 2.96875 10.6797 3.14844 10.5391 3.28906C10.3984 3.42969 10.2188 3.5 10 3.5H1C0.78125 3.5 0.601562 3.42969 0.460938 3.28906C0.320312 3.14844 0.25 2.96875 0.25 2.75ZM1.75 6.5C1.75 6.28125 1.82031 6.10156 1.96094 5.96094C2.10156 5.82031 2.28125 5.75 2.5 5.75H8.5C8.71875 5.75 8.89844 5.82031 9.03906 5.96094C9.17969 6.10156 9.25 6.28125 9.25 6.5C9.25 6.71875 9.17969 6.89844 9.03906 7.03906C8.89844 7.17969 8.71875 7.25 8.5 7.25H2.5C2.28125 7.25 2.10156 7.17969 1.96094 7.03906C1.82031 6.89844 1.75 6.71875 1.75 6.5ZM7 10.25C7 10.4688 6.92969 10.6484 6.78906 10.7891C6.64844 10.9297 6.46875 11 6.25 11H4.75C4.53125 11 4.35156 10.9297 4.21094 10.7891C4.07031 10.6484 4 10.4688 4 10.25C4 10.0312 4.07031 9.85156 4.21094 9.71094C4.35156 9.57031 4.53125 9.5 4.75 9.5H6.25C6.46875 9.5 6.64844 9.57031 6.78906 9.71094C6.92969 9.85156 7 10.0312 7 10.25Z'
        fill={fill}
      />
    </svg>
  );
};
